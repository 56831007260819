import styled from 'styled-components'

import { NextLink } from '@/components/_shared/NextLink'
import { IPlace } from '@/types/main'

interface IProps {
  place: IPlace
}

export const PlaceAddressLink: React.FC<IProps> = ({ place }) => {
  if (!place) return null

  return (
    <NextLink
      href={`https://maps.google.com/?q=${place.address},${place.city},${place.postalCode}`}
      target="_blank"
      rel="external nofollow"
    >
      <Bold>{place.title}</Bold>
      <p>{place.address}</p>
    </NextLink>
  )
}

const Bold = styled.p`
  ${({ theme }) => theme.typo.bold}
`
