import { LinkProps } from 'next/link'
import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { CustomButtonLink } from '@/components/_custom/CustomButton'
import { Container } from '@/components/_layout/Container'
import { GenericSlice } from '@/components/_layout/GenericSlice'
import { Markdown } from '@/components/_shared/Markdown'
import { DisciplineListComma } from '@/components/discipline/DisciplineListComma'
import { TeacherAvatar } from '@/components/teacher/TeacherAvatar'
import { ITeacher } from '@/types/main'
import { DEVICE_WIDTH } from '@/utils/constants'

interface IProps {
  teacher: ITeacher
  buttonText: string
  buttonLink: LinkProps['href']
}

export const TeacherDetailSlice: React.FC<IProps> = ({
  teacher,
  buttonText,
  buttonLink,
}) => {
  const intl = useIntl()

  return (
    <GenericSlice
      sectionTitle={intl.formatMessage({
        defaultMessage: 'Votre professeur',
        description: 'TeacherDetailSlice: section title',
      })}
    >
      <StyledContainer>
        <TeacherAvatar teacher={teacher} />

        <TeacherInfosWrapper>
          <Name>{teacher.fullName}</Name>
          <StyledDisciplineListComma
            disciplineList={teacher.disciplinesOnly}
            enableLinks
          />
          <TeacherBio>{teacher.bio}</TeacherBio>

          <StyledButtonLink href={buttonLink} outlined>
            {buttonText}
          </StyledButtonLink>
        </TeacherInfosWrapper>
      </StyledContainer>
    </GenericSlice>
  )
}

const StyledContainer = styled(Container)`
  align-items: center;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    align-items: flex-start;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }
`
const TeacherInfosWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing.ms};

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    grid-column: 7 / 12;
    margin-top: 0;
  }
`
const Name = styled.h2`
  ${({ theme }) => theme.typo.h2}

  margin-bottom: ${({ theme }) => theme.spacing.s};
  text-align: center;

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    text-align: left;
  }
`
const StyledDisciplineListComma = styled(DisciplineListComma)`
  margin-bottom: ${({ theme }) => theme.spacing.ms};
`
const TeacherBio = styled(Markdown)`
  color: ${({ theme }) => theme.color.greyDarker};
  margin: ${({ theme }) => theme.spacing.s} 0;

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    margin: ${({ theme }) => theme.spacing.m} 0;
  }
`
const StyledButtonLink = styled(CustomButtonLink)`
  margin: ${({ theme }) => theme.spacing.ms} auto 0;
  width: fit-content;

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    margin: ${({ theme }) => theme.spacing.m} 0 0;
  }
`
