import React from 'react'
import { FormattedDate } from 'react-intl'
import styled from 'styled-components'

import { CustomButtonLink } from '@/components/_custom/CustomButton'
import { Container } from '@/components/_layout/Container'
import { CtaColumn } from '@/components/_shared/CtaColumn'
import { InlineFavoriteToggler } from '@/components/_shared/FavoriteToggler'
import { TimeAndDuration } from '@/components/_shared/TimeAndDuration'
import { PillarPractice } from '@/components/discipline/PillarPractice'
import { LessonCta } from '@/components/lesson/lesson/misc/LessonCta'
import { PlaceAddressLink } from '@/components/place/misc/PlaceAddressLink'
import { PlaceCity } from '@/components/place/misc/PlaceCity'
import { PlaceEquipmentList } from '@/components/place/PlaceEquipmentList'
import { TeacherMinimal } from '@/components/teacher/TeacherMinimal'
import { ILessonDecorated } from '@/types/main'
import { DEVICE_WIDTH } from '@/utils/constants'
import { buildInstructorRoute } from '@/utils/route'

interface IProps {
  lesson: ILessonDecorated
}

export const LessonHeroSlice: React.FC<IProps> = ({ lesson }) => (
  <LessonHeroSliceContainer>
    <LessonHeroSliceContentColumn>
      <LessonHeroSliceTitle>
        {lesson.title} {lesson.serie && <InlineFavoriteToggler lessonSerie={lesson} />}
      </LessonHeroSliceTitle>

      <LessonMainInfosContainer>
        <LessonMainInfosContainerTop>
          <PillarPractice practice={lesson.discipline?.practice} enableLink />
          <div>{lesson.level?.title}</div>
        </LessonMainInfosContainerTop>

        <PlaceEquipmentListStyled place={lesson.place} />
      </LessonMainInfosContainer>

      <StyledTeacherMinimal
        teacher={lesson.teacher}
        teacherLink={buildInstructorRoute(lesson.teacher)}
        enableDisciplineListLinks
      />
    </LessonHeroSliceContentColumn>

    <LessonHeroSliceCtaColumn hideTitle>
      <LessonHeroSliceCtaContainer>
        <LessonHeroSliceBold>
          <FormattedDate
            value={lesson.startAt}
            weekday="long"
            day="numeric"
            month="long"
          />
        </LessonHeroSliceBold>
        <TimeAndDuration startTime={lesson.startAt} duration={lesson.duration} />
      </LessonHeroSliceCtaContainer>

      <LessonHeroSliceCtaContainer>
        <PlaceAddressLink place={lesson.place} />
        <PlaceCity lessonSerie={lesson} />
      </LessonHeroSliceCtaContainer>

      <LessonBookingButton lesson={lesson} />
    </LessonHeroSliceCtaColumn>
  </LessonHeroSliceContainer>
)

const LessonBookingButton: React.FC<IProps> = ({ lesson }) => {
  if (!['no'].includes(lesson.authUserAccess)) {
    return <LessonCta lesson={lesson} />
  }

  return null
}

export const LessonHeroSliceContainer = styled(Container)`
  margin-bottom: ${({ theme }) => theme.spacing.xxxl};
  margin-top: ${({ theme }) => theme.spacing.m};

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    align-items: center;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    margin-bottom: 120px;
    margin-top: ${({ theme }) => theme.spacing.l};
  }
`
export const LessonHeroSliceContentColumn = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacing.s};
  justify-items: center;

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    grid-column: 2 / 7;
    text-align: left;
  }
`
export const LessonHeroSliceTitle = styled.h1`
  ${({ theme }) => theme.typo.h1}

  text-align: center;
`
export const LessonMainInfosContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.ms};
`
export const LessonMainInfosContainerTop = styled.div`
  ${({ theme }) => theme.typo.bold};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  & > *:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing.s};
  }
`
export const PlaceEquipmentListStyled = styled(PlaceEquipmentList)`
  justify-content: center;
`
export const LessonHeroSliceCtaContainer = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacing.xs};
  justify-items: center;
`
export const LessonHeroSliceCtaColumn = styled(CtaColumn)`
  text-align: center;

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    grid-column: 8 / 12;
    justify-self: center;
  }
`
const StyledTeacherMinimal = styled(TeacherMinimal)`
  & > div:nth-child(2) {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
export const LessonHeroSliceBold = styled.p`
  ${({ theme }) => theme.typo.bold}
`
