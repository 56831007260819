import { SWRConfiguration } from 'swr'

import {
  getSurveyUserComments,
  IGetSurveyUserCommentsArgs,
  IGetSurveyUserCommentsResponse,
} from '@/services/api-rest'
import { HTTPError } from '@/utils/api-helpers'
import { swrImmutableConfig } from '@/utils/swr'

import { useSWRCustom } from './useSwr-custom'

export const SWR_KEY__SURVEY_USER_COMMENT_RESULTS = 'surveyUserComment_results'

interface IReturnType {
  surveyUserCommentListLoading: boolean
  surveyUserCommentList: IGetSurveyUserCommentsResponse
  surveyUserCommentListError: HTTPError
}

export const useSWRSurveyUserCommentResults = (
  params: IGetSurveyUserCommentsArgs,
  swrConfig: SWRConfiguration = swrImmutableConfig
): IReturnType => {
  const hasParam =
    params.serieUuid ||
    params.teacherId ||
    params.placeId ||
    params.placePostalCode ||
    params.lessonArea
  const { data, error, isLoading } = useSWRCustom<IGetSurveyUserCommentsResponse>(
    [hasParam && SWR_KEY__SURVEY_USER_COMMENT_RESULTS],
    getSurveyUserComments,
    params,
    swrConfig
  )

  return {
    surveyUserCommentListLoading: isLoading,
    surveyUserCommentList: data,
    surveyUserCommentListError: error,
  }
}
