import React from 'react'
import { defineMessages, FormattedMessage, MessageDescriptor } from 'react-intl'
import styled from 'styled-components'

import { IPlace } from '@/types/main'

interface IProps {
  place: IPlace
  className?: string
}

export const PlaceEquipmentList: React.FC<IProps> = ({ place, className }) => {
  if (!place?.equipments.length) {
    return null
  }

  return (
    <Wrapper className={className}>
      <FormattedMessage
        tagName={Label}
        defaultMessage="{nbPlaceEquipments, plural, one {Equipement disponible} other {Equipements disponibles}} sur place :"
        description="PlaceEquipmentList: label"
        values={{
          nbPlaceEquipments: place.equipments.length,
        }}
      />

      {place.equipments.map((equipment, index) => (
        <React.Fragment key={equipment}>
          {index > 0 && ', '}
          <FormattedMessage
            tagName="span"
            {...(placeEquipmentMessages[equipment] ?? placeEquipmentMessages['default'])}
          />
        </React.Fragment>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  color: ${({ theme }) => theme.color.grey};
  margin-top: ${({ theme }) => theme.spacing.s};
`
const Label = styled.span`
  margin-right: ${({ theme }) => theme.spacing.xxs};
`

const placeEquipmentMessages = defineMessages<string, MessageDescriptor>({
  withShower: {
    defaultMessage: 'Douche',
    description: 'PlaceEquipmentList: withShower',
  },
  withMat: {
    defaultMessage: 'Tapis',
    description: 'PlaceEquipmentList: withMat',
  },
  default: {
    defaultMessage: '?',
    description: 'PlaceEquipmentList: default',
  },
})
