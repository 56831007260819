import React from 'react'
import styled from 'styled-components'

import { NextImageAvatar } from '@/components/_shared/NextImage'
import { ITeacher } from '@/types/main'
import { DEVICE_WIDTH } from '@/utils/constants'
import { getLinearShadow } from '@/utils/style'

interface IProps {
  teacher: ITeacher
  className?: string
}

export const TeacherAvatar: React.FC<IProps> = ({ teacher, className }) => (
  <Wrapper className={className}>
    <NextImageAvatar
      src={teacher.avatarPath}
      alt={teacher.name}
      sizes={`(max-width: ${DEVICE_WIDTH.MOBILE}px) 90vw, 35vw`}
    />
  </Wrapper>
)

const Wrapper = styled.div`
  aspect-ratio: 1;
  position: relative;
  width: 100%;

  @supports not (aspect-ratio: 1) {
    padding-top: 100%;
  }
  ${({ theme }) => getLinearShadow('100%', '100%', '0', theme.spacing.s)}

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    grid-column: 2 / 6;

    &&::before {
      left: -${({ theme }) => theme.spacing.s};
      top: ${({ theme }) => theme.spacing.m};
    }
  }
`
