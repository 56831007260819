import React from 'react'
import styled from 'styled-components'

import { IPropertyValueDiscipline } from '@/types/main'
import { buildDisciplineRoute } from '@/utils/route'

import { NextLink } from '../_shared/NextLink'

interface IProps {
  practice: IPropertyValueDiscipline['practice']
  enableLink?: boolean
  className?: string
}

export const PillarPractice: React.FC<IProps> = ({
  practice,
  enableLink = false,
  className,
}) => {
  if (!practice) {
    return null
  }

  if (!enableLink) {
    return <StyledP className={className}>{practice.title}</StyledP>
  }

  return (
    <StyledLink href={buildDisciplineRoute(practice)} className={className}>
      {practice.title}
    </StyledLink>
  )
}

const StyledP = styled.p`
  ${({ theme }) => theme.typo.bold}
`
const StyledLink = styled(NextLink)`
  ${({ theme }) => theme.typo.bold}
`
