import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import styled from 'styled-components'

import { Container } from '@/components/_layout/Container'
import { Markdown } from '@/components/_shared/Markdown'
import { NextImage } from '@/components/_shared/NextImage'
import { StaticMap } from '@/components/_shared/StaticMap'
import { ILessonDecorated } from '@/types/main'
import { DEVICE_WIDTH } from '@/utils/constants'
import { getLinearShadow } from '@/utils/style'

interface IProps {
  lesson: ILessonDecorated
}

export const LessonDescriptionSlice: React.FC<IProps> = ({ lesson }) => {
  const intl = useIntl()

  const displayAccessInformation =
    lesson.place?.accessInformation && lesson.hasAuthUserActiveBookings()

  return (
    <LessonDescriptionSliceContainer>
      <LessonDescriptionSliceContent>
        <FormattedMessage
          tagName={LessonDescriptionSliceTitle}
          defaultMessage="Description"
          description="LessonDescriptionSlice: description"
        />

        <LessonDescriptionSliceDescription>
          {lesson.description ?? lesson.discipline?.misc.description}
        </LessonDescriptionSliceDescription>

        {(lesson.information || displayAccessInformation) && (
          <LessonDescriptionSliceInformationWrapper>
            <FormattedMessage
              tagName={LessonDescriptionSliceInformationTitle}
              defaultMessage="Informations"
              description="LessonDescriptionSlice: informations"
            />
            {lesson.information && (
              <LessonDescriptionSliceInformation>
                {lesson.information}
              </LessonDescriptionSliceInformation>
            )}

            {displayAccessInformation && (
              <LessonDescriptionSliceInformation>
                {lesson.place.accessInformation}
              </LessonDescriptionSliceInformation>
            )}
          </LessonDescriptionSliceInformationWrapper>
        )}
      </LessonDescriptionSliceContent>

      {lesson.place ? (
        <LessonDescriptionSliceStaticMap place={lesson.place} />
      ) : (
        <LessonDescriptionSliceImageShadow>
          <LessonDescriptionSliceImageWrapper>
            <NextImage
              src={
                lesson.discipline?.misc.artwork_path ?? '/pictures/default_discipline.jpg'
              }
              alt={intl.formatMessage(
                {
                  defaultMessage: 'Cours de {discipline} chez OLY Be',
                  description: 'LessonDescriptionSlice: image alt discipline',
                },
                { discipline: lesson.discipline?.title ?? 'yoga' }
              )}
              imgixPreconfig="capsuleCropFace"
              sizes={`(max-width: ${DEVICE_WIDTH.MOBILE}px) 70vw, 20vw`}
              fill
            />
          </LessonDescriptionSliceImageWrapper>
        </LessonDescriptionSliceImageShadow>
      )}
    </LessonDescriptionSliceContainer>
  )
}

export const LessonDescriptionSliceContainer = styled(Container)`
  display: grid;
  gap: ${({ theme }) => theme.spacing.ml};
  margin-bottom: 80px;
  margin-top: 80px;

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    align-items: center;
    grid-template-columns: repeat(12, 1fr);
  }
`
export const LessonDescriptionSliceContent = styled.div`
  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    grid-column: 2 / 7;
  }
`
export const LessonDescriptionSliceTitle = styled.h2`
  ${({ theme }) => theme.typo.h3}
  ${({ theme }) => theme.typo.pointBefore}

  margin-bottom: ${({ theme }) => theme.spacing.ms};
`
export const LessonDescriptionSliceDescription = styled(Markdown)`
  color: ${({ theme }) => theme.color.greyDarker};
  margin-bottom: ${({ theme }) => theme.spacing.ms};
  padding-left: ${({ theme }) => theme.spacing.ms};

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    padding-left: ${({ theme }) => theme.spacing.m};
  }
`
export const LessonDescriptionSliceInformationWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.beigeLight};
  border-radius: ${({ theme }) => theme.spacing.s};
  margin-top: ${({ theme }) => theme.spacing.m};
  padding: ${({ theme }) => theme.spacing.m};
`
export const LessonDescriptionSliceInformationTitle = styled.p`
  ${({ theme }) => theme.typo.h5};
  ${({ theme }) => theme.typo.bold};

  margin-bottom: ${({ theme }) => theme.spacing.s};
`
export const LessonDescriptionSliceInformation = styled(Markdown)`
  color: ${({ theme }) => theme.color.greyDarker};
`
export const LessonDescriptionSliceStaticMap = styled(StaticMap)`
  aspect-ratio: 1;
  border-radius: ${({ theme }) => theme.spacing.s};
  width: 100%;

  @supports not (aspect-ratio: 1) {
    padding-bottom: 100%;
  }

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    grid-column: 8 / 12;
    margin-top: ${({ theme }) => theme.spacing.m};
  }
`
export const LessonDescriptionSliceImageShadow = styled.div`
  padding: 0 ${({ theme }) => theme.spacing.ms};

  ${({ theme }) => getLinearShadow('100%', '100%', '-16px', theme.spacing.ml)}
  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    grid-column: 8 / 12;
    padding: 0;
  }
`
export const LessonDescriptionSliceImageWrapper = styled.div`
  aspect-ratio: 8/11;
  border-radius: 283px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 80%;

  @supports not (aspect-ratio: 1) {
    padding-top: 110%;
  }
`
