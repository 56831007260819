import Image from 'next/image'
import React from 'react'
import { IntlShape, useIntl } from 'react-intl'
import styled from 'styled-components'
import { theme } from 'theme'

import { Config } from '@/services/config'
import { IPlace } from '@/types/main'

interface IProps {
  place: IPlace
  size?: string
  className?: string
}

export const StaticMap: React.FC<IProps> = ({ place, size = '400x400', className }) => {
  const intl = useIntl()

  return (
    <Wrapper className={className}>
      <Image
        src={buildStaticMapUrl(place, size, intl)}
        alt={intl.formatMessage({
          defaultMessage: 'emplacement du studio sur une carte',
          description: 'StaticMap: img alt',
        })}
        fill
        loader={() => `${buildStaticMapUrl(place, size, intl)}&w=${size.split('x')[0]}`}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
`

const buildGoogleMapColor = (color: string) => `0x${color.split('#')[1]}`

const buildStaticMapUrl = (place: IPlace, size: string, intl: IntlShape): string => {
  const markersStyle = `size:small|color:${buildGoogleMapColor(
    theme.color.terracotaLight
  )}`

  const parameters = {
    markers: `${markersStyle}|${place.latitude},${place.longitude}`,
    size,
    key: Config.googleApiKey,
    scale: 2,
    zoom: 15,
    language: intl.defaultLocale,
  }

  const parametersString = Object.entries(parameters)
    .map(([key, value]) => [key, value].join('='))
    .join('&')

  const styles = [
    'feature:poi|visibility:off',
    'feature:administrative|visibility:off',
    `feature:road|color:${buildGoogleMapColor(theme.color.white)}`,
    `feature:road|element:labels.text.fill|color:${buildGoogleMapColor(
      theme.color.greyLight
    )}`,
    `feature:landscape|color:${buildGoogleMapColor(theme.color.greyLighter)}`,
    'feature:transit.line|visibility:off',
    'feature:water|element:labels|visibility:off',
  ].join('&style=')

  return `https://maps.googleapis.com/maps/api/staticmap?${parametersString}&style=${styles}`
}
