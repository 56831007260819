import { LinkProps } from 'next/link'
import React from 'react'
import styled from 'styled-components'

import { NextImageAvatar } from '@/components/_shared/NextImage'
import { NextLink } from '@/components/_shared/NextLink'
import { DisciplineListComma } from '@/components/discipline/DisciplineListComma'
import { ITeacher } from '@/types/main'

interface IProps {
  teacher: ITeacher | Partial<ITeacher>
  teacherLink?: LinkProps['href']
  enableDisciplineListLinks?: boolean
  className?: string
}

export const TeacherMinimal: React.FC<IProps> = ({
  teacher,
  teacherLink,
  enableDisciplineListLinks = false,
  className,
}) => (
  <Wrapper className={className}>
    <TeacherAvatarWrapper>
      <NextImageAvatar src={teacher.avatarPath} alt={teacher.name} sizes="72px" />
    </TeacherAvatarWrapper>

    <div>
      {teacherLink ? (
        <NextLink href={teacherLink}>
          <TeacherName>{teacher.fullName}</TeacherName>
        </NextLink>
      ) : (
        <TeacherName>{teacher.fullName}</TeacherName>
      )}

      <DisciplineListComma
        disciplineList={teacher.disciplinesOnly}
        enableLinks={enableDisciplineListLinks}
      />
    </div>
  </Wrapper>
)

const Wrapper = styled.div`
  align-items: center;
  display: flex;
`
const TeacherAvatarWrapper = styled.div`
  height: ${({ theme }) => theme.spacing.xxxl};
  margin-right: ${({ theme }) => theme.spacing.s};
  min-width: ${({ theme }) => theme.spacing.xxxl};
  position: relative;
`
const TeacherName = styled.p`
  ${({ theme }) => theme.typo.bold}
`
