import React from 'react'
import { useIntl } from 'react-intl'

import { Container } from '@/components/_layout/Container'
import { GenericSlice } from '@/components/_layout/GenericSlice'
import { FeedbacksCarousel } from '@/components/_shared/FeedbacksCarousel'
import { useSWRSurveyUserCommentResults } from '@/hooks/swr/useSwr-surveyUserCommentResults'
import { IGetSurveyUserCommentsArgs } from '@/services/api-rest'
import { buildFeedbacks } from '@/utils/feedbacks'

interface IProps {
  params: IGetSurveyUserCommentsArgs
}

export const DynamicSurveyFeedbackListCarouselSlice: React.FC<IProps> = ({ params }) => {
  const intl = useIntl()
  const {
    surveyUserCommentListLoading,
    surveyUserCommentList,
    surveyUserCommentListError,
  } = useSWRSurveyUserCommentResults(params)

  if (surveyUserCommentListLoading) return null
  if (surveyUserCommentListError) return null
  if (!surveyUserCommentList.length) return null

  return (
    <GenericSlice
      title={intl.formatMessage({
        defaultMessage: 'Paroles de Yogis',
        description: 'DynamicSurveysurveyUserCommentListCarouselSlice: title',
      })}
      sectionTitle={intl.formatMessage({
        defaultMessage: 'Nos élèves témoignent',
        description: 'DynamicSurveysurveyUserCommentListCarouselSlice: section title',
      })}
    >
      <Container>
        <FeedbacksCarousel feedbacks={buildFeedbacks(surveyUserCommentList)} />
      </Container>
    </GenericSlice>
  )
}
