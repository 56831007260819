import React from 'react'

export const useEffectOnce = (callback: () => void, when: any): void => {
  const hasRunOnce = React.useRef(false)
  React.useEffect(() => {
    if (when && !hasRunOnce.current) {
      callback()
      hasRunOnce.current = true
    }
  }, [when])
}
